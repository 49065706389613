import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Sidebar = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="sidebar">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className='p-0'>
                        <Accordion.Header>Get Started</Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item as={NavLink} to="/docs/welcomevion" eventKey="0" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Vion</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/quickstart"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            What is Vion BlockChain?
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionblockchain"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Overview of Vion Blockchain and Its Purpose
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/keyfeature"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Key Features of Vion Blockchain
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionsolving"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            How Vion is Solving Current Blockchain Challenges
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/whyvion" eventKey="1" className='p-0 inneraccordion'>
                                    <Accordion.Header>
                                    <h5>Why Choose Vion?</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/philosphy"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Philosophy and Core Vision
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/competitive"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Competitive Advantages Over Other Blockchains
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/started" eventKey="2" className='p-0 inneraccordion'>
                                    <Accordion.Header> <h5>Getting Started</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionquickly"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            New to Vion Blockchain?
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionprisma"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Vion Prisma (Testnet name)
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/overview"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Overview
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionkeys"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Key Terminologies
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/vionarchi" eventKey="3" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Vion Blockchain Architecture</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vioncore"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Core Components of Vion Blockchain Architecture
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/viondeveloper" eventKey="4" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Developer-Focused Infrastructure</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionsecurity"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Security Measures
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionsusta"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Sustainability
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionmodular"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Modular Design
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/vionsteps" eventKey="5" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Steps to Run a Full Node for Vion Chain</h5> </Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionprer"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Prerequisites
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionclone"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            1. Clone the Repository
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vioncreate"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            2. Create Your Configuration File
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionbuild"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            3. Build and Start Your Node
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/viondeposit"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            4. Deposit Vion Tokens
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionnotes"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Important Notes
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/eycosystem" eventKey="6" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Ecosystem and Applications </h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vioneco"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Ecosystem Overview
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionovereco"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Overview of Vion’s Ecosystem and Key Partners/Projects
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionwallet"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Supported Wallets, Exchanges, and Tools
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/vionbuilding" eventKey="7" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Building on Vion</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionguide"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Guide to Creating and Deploying dApps
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/viontools" eventKey="8" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Resources and Tools</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/whitepaper"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Whitepaper
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/faqs"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            FAQs
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/viontopics" eventKey="9" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Advanced Topics</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionscal"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Scalability Solutions
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionrech"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Research and Development
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                               
                            </Accordion>
                            <NavLink
                                exact
                                to="/docs/conclusion"
                                className={({ isActive }) => (isActive ? "active-link" : "")}
                            >
                                Conclusion
                            </NavLink>
                           
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="mobile-sidebar d-none">
                {/* <a href="/"> <img src="\logo.svg" alt="img" className='img-fluid' /></a> */}
                <a onClick={handleShow}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M3 17H21M3 12H21M3 7H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg></a>
            </div>


            <Offcanvas className="mobile-offcanvas" show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <img src="\logo.svg" alt="img" className='img-fluid' />
                </Offcanvas.Header>
                <Offcanvas.Body>
                <div className="sidebar">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className='p-0'>
                        <Accordion.Header>Get Started</Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item as={NavLink} to="/docs/welcomevion" eventKey="0" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Vion</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/quickstart"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            What is Vion BlockChain?
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionblockchain"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Overview of Vion Blockchain and Its Purpose
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/keyfeature"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Key Features of Vion Blockchain
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionsolving"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            How Vion is Solving Current Blockchain Challenges
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/whyvion" eventKey="1" className='p-0 inneraccordion'>
                                    <Accordion.Header>
                                    <h5>Why Choose Vion?</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/philosphy"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Philosophy and Core Vision
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/competitive"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Competitive Advantages Over Other Blockchains
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/started" eventKey="2" className='p-0 inneraccordion'>
                                    <Accordion.Header> <h5>Getting Started</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionquickly"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            New to Vion Blockchain?
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionprisma"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Vion Prisma (Testnet name)
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/overview"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Overview
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionkeys"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Key Terminologies
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/vionarchi" eventKey="3" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Vion Blockchain Architecture</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vioncore"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Core Components of Vion Blockchain Architecture
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/viondeveloper" eventKey="4" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Developer-Focused Infrastructure</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionsecurity"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Security Measures
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionsusta"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Sustainability
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionmodular"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Modular Design
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/vionsteps" eventKey="5" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Steps to Run a Full Node for Vion Chain</h5> </Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionprer"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Prerequisites
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionclone"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            1. Clone the Repository
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vioncreate"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            2. Create Your Configuration File
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionbuild"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            3. Build and Start Your Node
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/viondeposit"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            4. Deposit Vion Tokens
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionnotes"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Important Notes
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/eycosystem" eventKey="6" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Ecosystem and Applications </h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vioneco"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Ecosystem Overview
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionovereco"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Overview of Vion’s Ecosystem and Key Partners/Projects
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionwallet"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Supported Wallets, Exchanges, and Tools
                                        </NavLink>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/vionbuilding" eventKey="7" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Building on Vion</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionguide"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Guide to Creating and Deploying dApps
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/viontools" eventKey="8" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Resources and Tools</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/whitepaper"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Whitepaper
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/faqs"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            FAQs
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item as={NavLink} to="/docs/viontopics" eventKey="9" className='p-0 inneraccordion'>
                                    <Accordion.Header><h5>Advanced Topics</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <NavLink
                                            exact
                                            to="/docs/vionscal"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Scalability Solutions
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/docs/vionrech"
                                            className={({ isActive }) => (isActive ? "active-link" : "")}
                                        >
                                            Research and Development
                                        </NavLink>

                                    </Accordion.Body>
                                </Accordion.Item>
                               
                            </Accordion>
                            <NavLink
                                exact
                                to="/docs/conclusion"
                                className={({ isActive }) => (isActive ? "active-link" : "")}
                            >
                                Conclusion
                            </NavLink>
                           
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Sidebar;
