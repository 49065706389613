import React from 'react'
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
const Vionquickly = () => {
    return (
        <div className="content-wrapper">
            <div className="inside-content">

                <div>
                    <h1>New to Vion Blockchain?</h1>
                    <p>Welcome to Vion Blockchain! This section is designed to help beginners understand and get started with the Vion ecosystem. Whether you are a developer, a business, or a casual user, here’s how you can dive into the world of Vion:</p>
                    <Element name="Getting started" className='Getting started'>
                        <div id="Getting started">
                            {/* <h3>Getting started <span className="green">#</span></h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
                            </p> */}
                            <ol>
                                <li> <span className='greentext'>Getting Started Quickly</span><br></br>
                                    <ul>
                                        <li><span className="greypara"> Set up a compatible wallet to interact with the Vion network.<br>
                                        </br>
                                        <p>(https://vion.network/blogdetail/how-to-set-up-a-wallet-for-vion-blockchain)</p></span></li>
                                        <li><span className="greypara">Explore Vion's block explorer to view transactions and network status.
                                            <br></br>
                                            <p>(Testnet Explore <a href='http://3.83.26.144:35005/' target='blank'>http://3.83.26.144:35005/</a>)</p>
                                        </span></li>

                                    </ul>
                                </li>
                                <li> <span className='greentext'>Key Tools and Concepts</span><br></br>
                                    <ul>
                                        <li>  <span className='greentext'>Vion Wallets: </span><span className="greypara">Learn about wallets supported by Vion for secure token storage.<br>
                                        </br>
                                       </span></li>
                                        <li><span className='greentext'>RPC Endpoints: </span><span className="greypara">Understand how to connect to the network using RPC.

                                            
                                        </span></li>

                                    </ul>
                                </li>
                               

                            </ol>
                        </div>
                    </Element>
                    {/* <Element name="Start learning" className='Start learning'>
                        <div id="Start learning">
                            <h3>Start learning <span className="green">#</span></h3>
                            <p>
                                Eros dictum ante class sagittis dolor suspendisse pretium. Rutrum amet penatibus velit amet arcu sociosqu vivamus id.
                            </p>
                            <ul>
                                <li>Accounts  <span className="greypara">- Viverra habitant nisl aptent neque faucibus.</span></li>
                                <li>Fee on Vion  <span className="greypara">- Viverra habitant nisl aptent neque faucibus.</span></li>
                                <li>Transactions  <span className="greypara">- Viverra habitant nisl aptent neque faucibus.</span></li>
                                <li>Programs Derived Address  <span className="greypara">- Viverra habitant nisl aptent neque faucibus.</span></li>
                                <li>Cross Program Invocation  <span className="greypara">- Himenaeos vitae natoque class; elit malesuada maximus fringilla congue dictum scelerisque.</span></li>
                            </ul>
                        </div>
                    </Element>
                    <Element name="Vion Features" className='Vion Features'>
                        <div id="Vion Features">
                            <h3>Vion Features <span className="green">#</span></h3>
                            <p>
                                Urna at dictum nunc eu donec; sociosqu lacinia. Pharetra suspendisse sagittis convallis nullam commodo interdum. Iaculis malesuada lobortis nisi curabitur massa nisl.
                            </p>
                            <div className="feraturesmain">
                                <div className="featuresinnerhead">
                                    <h4 className="featurehead heading">Feature</h4>
                                    <h4 className="featurehead para">Description</h4>
                                </div>
                                <div className="featuresinner">
                                    <p className="featurespara heading">Onchain Program Development</p>
                                    <p className="featurespara para">Nec nec vehicula sodales posuere porttitor molestie. Massa volutpat congue ut fermentum commodo feugiat.</p>
                                </div>
                                <div className="featuresinner dark">
                                    <p className="featurespara heading">400ms Slot Times</p>
                                    <p className="featurespara para">Elit tellus felis malesuada mi tincidunt facilisis vulputate. Id diam sed integer urna platea ac. Vulputate fusce eros mi nulla ultricies volutpat, laoreet sit. </p>
                                </div>
                                <div className="featuresinner">
                                    <p className="featurespara heading">Onchain Program Development</p>
                                    <p className="featurespara para">Nec nec vehicula sodales posuere porttitor molestie. Massa volutpat congue ut fermentum commodo feugiat.</p>
                                </div>
                                <div className="featuresinner dark">
                                    <p className="featurespara heading">400ms Slot Times</p>
                                    <p className="featurespara para">Elit tellus felis malesuada mi tincidunt facilisis vulputate. Id diam sed integer urna platea ac. Vulputate fusce eros mi nulla ultricies volutpat, laoreet sit. </p>
                                </div>
                            </div>
                        </div>
                    </Element>
                    <Element name="How to Use These Docs" className='How to Use These Docs'>
                        <div id="How to Use These Docs">
                            <h3>How to Use These Docs <span className="green">#</span></h3>
                            <p>
                                At praesent consectetur elit etiam quam vitae. Morbi litora dictum iaculis enim lectus pretium. Dis hendrerit cras platea facilisis a rutrum. Tristique ligula vivamus habitasse imperdiet fringilla dapibus. Aliquet cursus convallis nisi rhoncus tortor neque. Placerat praesent maecenas; primis et eu tincidunt mattis. Aenean dis luctus magnis; potenti augue amet velit.
                            </p>
                            <p>
                                usto molestie orci habitant vehicula sodales phasellus. <span className="green">Scelerisque ridiculus</span> fusce nisi in platea. Sem conubia arcu eleifend aptent adipiscing.
                            </p>
                        </div>
                    </Element> */}
                    <div className="bottombtns">
                               <Link to="/docs/started">
                        <button className="innerbtn">
                            Previous
                            <span className='greentext'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M10.0833 7.33337L6.41659 11L10.0833 14.6667M15.5833 7.33337L11.9166 11L15.5833 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Quick Start</span>
                        </button>
                        </Link>
                        <Link to="/docs/vionprisma">
                        <button className="innerbtn btntextlast">
                            Next
                            <span className='greentext'>Quick Start <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M11.9166 7.33337L15.5833 11L11.9166 14.6667M6.41663 7.33337L10.0833 11L6.41663 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></span>
                        </button>
                        </Link>
                    </div>
                </div>


            </div>
            {/* <div className="right-sidebar">
                <div className="main-heading">
                    <span>Table of Contents</span>
                </div>
                <div className="rightlinks">
                    <Link to="Getting started" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Getting started</Link>
                    <Link to="Start learning" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Start learning</Link>
                    <Link to="Vion Features" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Vion Features</Link>
                    <Link to="How to Use These Docs" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                    <Link to="terminology" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Why Vion</Link>
                    <Link to="terminology" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Vion Features</Link>
                    <Link to="terminology" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                    <Link to="terminology" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Need Help</Link>
                </div>
            </div> */}
        </div>
    )
}

export default Vionquickly