import React from 'react'
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
const Faqs = () => {
    return (
        <div className="content-wrapper">
            <div className="inside-content">

                <div>
                    <h2>FAQs</h2>
                    <h1>General Questions</h1>

                    <Element name="Getting started" className='Getting started'>
                        <div id="Getting started">
                            <ol>

                                <li> <span className='greentext'>What is Vion Blockchain?</span>
                                    <ul>
                                        <p>Vion is a next-generation Layer 1 blockchain designed for scalability, security, and sustainability. It supports decentralized applications (dApps), NFTs, DeFi, and enterprise-grade solutions.</p>
                                    </ul>
                                </li>
                                <li> <span className='greentext'>How is Vion different from other blockchains?</span>
                                    <ul>
                                        <p>Vion focuses on combining eco-friendliness with high scalability, interoperability, and a user-friendly developer ecosystem. Its unique consensus mechanism ensures efficiency and security.</p>

                                    </ul>
                                </li>

                                <li> <span className='greentext'>Is Vion compatible with Ethereum and other EVM chains?</span>
                                    <ul>
                                        <p>Yes, Vion is fully EVM-compatible, making it easy for developers to port dApps and smart contracts from Ethereum and similar blockchains.</p>

                                    </ul>
                                </li>
                                <h1>Development Questions</h1>
                                <li> <span className='greentext'>IHow can I build on Vion Blockchain?</span>
                                    <ul>
                                        <p>Developers can use tools like Truffle, Hardhat, and Remix to deploy smart contracts on Vion. Refer to the "Building on Vion" section for detailed tutorials.</p>

                                    </ul>
                                </li>
                                <li> <span className='greentext'>What programming languages does Vion support?</span>
                                    <ul>
                                        <p>Vion supports Solidity, the primary language for writing Ethereum-compatible smart contracts.</p>

                                    </ul>
                                </li>
                                <li> <span className='greentext'>Where can I access Vion’s developer tools and SDKs?</span>
                                    <ul>
                                        <p>Developer tools, SDKs, and APIs are available on the official Vion Developer Portal.</p>

                                    </ul>
                                </li>
                                <h1>Node and Validator Questions</h1>
                                <li> <span className='greentext'>How do I set up a node on Vion Blockchain?</span>
                                    <ul>
                                        <p>Check the "Nodes and Validators" section for a step-by-step guide to setting up a full node or becoming a validator.</p>

                                    </ul>
                                </li>
                                <li> <span className='greentext'>What are the staking requirements for becoming a validator?</span>
                                    <ul>
                                        <p>Validators need to stake a minimum amount of Vion tokens, as detailed in the "Nodes and Validators" section. This ensures active participation and network security.</p>

                                    </ul>
                                </li>
                                <li> <span className='greentext'>What rewards do validators earn?</span>
                                    <ul>
                                        <p>Validators earn Vion tokens as rewards for validating transactions and producing blocks. Rewards depend on staking amounts and performance.</p>

                                    </ul>
                                </li>

                                <h1>User Questions</h1>
                                <li> <span className='greentext'>Which wallets support Vion Blockchain?</span>
                                    <ul>
                                        <p>Vion is compatible with MetaMask, WalletConnect, and other popular wallets. Visit the "Supported Wallets" section for details.</p>

                                    </ul>
                                </li>
                                <li> <span className='greentext'>Where can I buy Vion tokens?</span>
                                    <ul>
                                        <p>Vion tokens are available on major centralized and decentralized exchanges. Check the "Exchanges" section for a list of supported platforms.</p>

                                    </ul>
                                </li>
                                <li> <span className='greentext'>What is the Vion testnet?</span>
                                    <ul>
                                        <p>applications without real-world risks. You can request free test tokens from the Vion Faucet.</p>

                                    </ul>
                                </li>
                                <h1>Contact and Support</h1>
                                <p>For assistance, collaboration, or to join the Vion community:</p>
                                <ul>
                                    <li> <span className='greentext'>Email Support: </span>
                                        <span className="greypara"> support@vionchain.com</span>
                                    </li>
                                    <li> <span className='greentext'>Community Channels: </span>
                                        <span className="greypara"> Connect with us on social platforms for updates, discussions, and announcements.</span>
                                    </li>   
                                    <li> 
                                        <span className="greypara"> Twitter</span>
                                    </li>  
                                    <li>
                                        <span className="greypara">Discord</span>
                                    </li>  
                                    <li>
                                        <span className="greypara">Telegram</span>
                                    </li>  
                                    <li>
                                        <span className="greypara">GitHub</span>
                                    </li>  
                                    <p>Vion's team and community are here to support you every step of the way!
                                    </p>
                                </ul>
                            </ol>
                        </div>
                    </Element>

                    <div className="bottombtns">
                             <Link to="/docs/whitepaper">
                        <button className="innerbtn">
                            Previous
                            <span className='greentext'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M10.0833 7.33337L6.41659 11L10.0833 14.6667M15.5833 7.33337L11.9166 11L15.5833 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Quick Start</span>
                        </button>
                        </Link>
                        <Link to="/docs/viontopics">
                        <button className="innerbtn btntextlast">
                            Next
                            <span className='greentext'>Quick Start <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M11.9166 7.33337L15.5833 11L11.9166 14.6667M6.41663 7.33337L10.0833 11L6.41663 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></span>
                        </button>
                        </Link>
                    </div>
                </div>


            </div>
            {/* <div className="right-sidebar">
                       <div className="main-heading">
                           <span>Table of Contents</span>
                       </div>
                       <div className="rightlinks">
                           <Link to="Getting started" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Getting started</Link>
                           <Link to="Start learning" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Start learning</Link>
                           <Link to="Vion Features" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Vion Features</Link>
                           <Link to="How to Use These Docs" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Why Vion</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Vion Features</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Need Help</Link>
                       </div>
                   </div> */}
        </div>
    )
}

export default Faqs