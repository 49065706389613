import React from 'react'
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
const Vionguide = () => {
    return (
        <div className="content-wrapper">
            <div className="inside-content">

                <div>
                    <h1>Guide to Creating and Deploying dApps</h1>

                    <Element name="Getting started" className='Getting started'>
                        <div id="Getting started">
                            <ol>

                                <li> <span className='greentext'>Set Up Your Development Environment: </span>
                                    <ul>
                                        <li>
                                            <span className="greypara">Install tools like Truffle, Hardhat, or Remix to write and deploy smart contracts.<br>
                                            </br>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="greypara">Configure the environment to connect with Vion’s RPC endpoint.<br>
                                            </br>
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                                <li> <span className='greentext'>Write and Test Your Smart Contracts:</span>
                                    <ul>
                                        <li>
                                            <span className="greypara">Use <span className='greentext'>Solidity </span> to develop contracts<br>
                                            </br>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="greypara">Test on the <span className='greentext'>Vion Testnet  </span> with free test tokens available from the faucet.<br>
                                            </br>
                                            </span>
                                        </li>
                                    </ul>
                                </li>

                                <li> <span className='greentext'>Deploy on Vion Mainnet:</span>
                                    <ul>
                                        <li>
                                            <span className="greypara">Once tested and verified, deploy your dApps to the mainnet.<br>
                                            </br>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="greypara">Use tools like MetaMask to interact with your application.<br>
                                            </br>
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                                <p>Vion’s robust ecosystem and developer-friendly infrastructure make it a prime choice for building scalable, efficient, and innovative blockchain solutions. Whether you’re developing your first dApp or scaling an enterprise application, Vion has the tools to support you.</p>



                            </ol>
                        </div>
                    </Element>

                    <div className="bottombtns">
                          <Link to="/docs/vionbuilding">
                        <button className="innerbtn">
                            Previous
                            <span className='greentext'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M10.0833 7.33337L6.41659 11L10.0833 14.6667M15.5833 7.33337L11.9166 11L15.5833 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Quick Start</span>
                        </button>
                        </Link>
                        <Link to="/docs/viontools">
                        <button className="innerbtn btntextlast">
                            Next
                            <span className='greentext'>Quick Start <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M11.9166 7.33337L15.5833 11L11.9166 14.6667M6.41663 7.33337L10.0833 11L6.41663 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></span>
                        </button>
                        </Link>
                    </div>
                </div>


            </div>
            {/* <div className="right-sidebar">
                       <div className="main-heading">
                           <span>Table of Contents</span>
                       </div>
                       <div className="rightlinks">
                           <Link to="Getting started" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Getting started</Link>
                           <Link to="Start learning" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Start learning</Link>
                           <Link to="Vion Features" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Vion Features</Link>
                           <Link to="How to Use These Docs" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Why Vion</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Vion Features</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Need Help</Link>
                       </div>
                   </div> */}
        </div>
    )
}

export default Vionguide