import React from 'react'
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
const Vionprisma = () => {
    return (
        <div className="content-wrapper">
            <div className="inside-content">

                <div>
                    <h1>Vion Prisma <span className='prismaspan'>(Testnet name)</span> </h1>
                    <p>Below are the details for the Vion Testnet.
                    </p>

                    <div>
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Network Name </td>
                                    <td>Vion Prisma Testnet</td>
                                </tr>
                                <tr>
                                    <td>RPC Endpoint</td>
                                    <td>http://3.83.26.144:32002
                                    </td>
                                </tr>
                                <tr>
                                    <td>Web Socket</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Chain ID</td>
                                    <td>28000</td>
                                </tr>
                                <tr>
                                    <td>Currency Symbol</td>
                                    <td>Prism</td>
                                </tr>
                                <tr>
                                    <td>Block Explorer</td>
                                    <td>http://3.83.26.144:35005/
                                    </td>
                                </tr>
                                <tr>
                                    <td>Faucet</td>
                                    <td>Coming Soon</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <ul>
                        <li> <span className='greentext'>Faucet: </span><span className="greypara">Use the testnet faucet to obtain test tokens for experimentation.
                        </span></li>
                    </ul>
                    <p>(COMING SOON)</p>
                    <div className="bottombtns">
                        <Link to="/docs/vionquickly">
                            <button className="innerbtn">
                                Previous
                                <span className='greentext'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M10.0833 7.33337L6.41659 11L10.0833 14.6667M15.5833 7.33337L11.9166 11L15.5833 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> Quick Start</span>
                            </button>
                        </Link>
                        <Link to="/docs/overview">
                            <button className="innerbtn btntextlast">
                                Next
                                <span className='greentext'>Quick Start <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M11.9166 7.33337L15.5833 11L11.9166 14.6667M6.41663 7.33337L10.0833 11L6.41663 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></span>
                            </button>
                        </Link>
                    </div>
                </div>


            </div>
            {/* <div className="right-sidebar">
                <div className="main-heading">
                    <span>Table of Contents</span>
                </div>
                <div className="rightlinks">
                    <Link to="Getting started" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Getting started</Link>
                    <Link to="Start learning" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Start learning</Link>
                    <Link to="Vion Features" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Vion Features</Link>
                    <Link to="How to Use These Docs" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                    <Link to="terminology" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Why Vion</Link>
                    <Link to="terminology" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Vion Features</Link>
                    <Link to="terminology" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                    <Link to="terminology" activeClass="active-link"
                        spy={true} smooth={true} duration={300}>Need Help</Link>
                </div>
            </div> */}
        </div>
    )
}

export default Vionprisma