import React from 'react'
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
const Vioncreate = () => {
    return (
        <div className="content-wrapper">
            <div className="inside-content">

                <div>
                    <Element name="Getting started" className='Getting started'>
                        <div id="Getting started">
                            <ol>
                                <h1>2. Create Your Configuration File </h1>


                                <p>Copy the sample configuration file to create a new<span> config.json</span> file:</p>
                                <p>bash</p>
                                <p>Copy code</p>
                                <div class="code-style">
                                    <a href="" target="_blank" rel="noopener noreferrer">
                                        cp config.sample.json config.json
                                    </a>
                                </div>
                                <ul>
                                    <li> <span className='greypara'>Open the <span className='greentext'>config.json</span>  file in a text editor (e.g., VS Code, Sublime Text, Vim, Nano).
                                    </span> </li>
                                </ul>



                                <h1 className='update_text'>Update the MNEMONIC Field</h1>
                                <p>Replace the  <span>MNEMONIC </span> field with your unique seed phrase using the following pattern:</p>
                                <p>json</p>
                                <p>Copy code</p>
                                <div class="code-style">
                                    <a href="" target="_blank" rel="noopener noreferrer">
                                        "MNEMONIC": "apple banana cat dog elephant fox giraffe"
                                    </a>
                                </div>
                                <ul>
                                    <li> <span className='greentext'>Note:</span> <span className='greypara'>The example mnemonic above is for demonstration purposes. Always generate a new mnemonic using a trusted source like <a href='https://iancoleman.io/bip39/' target='blank'>this website. </a>
                                    </span> </li>
                                    <li><span className='greypara'>Securely store your mnemonic phrase as it is the only way to access your funds or withdraw rewards. Do not share or store it in insecure locations.
                                    </span> </li>
                                </ul>




                            </ol>
                        </div>
                    </Element>

                    <div className="bottombtns">
                        <Link to="/docs/vionclone">
                            <button className="innerbtn">
                                Previous
                                <span className='greentext'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M10.0833 7.33337L6.41659 11L10.0833 14.6667M15.5833 7.33337L11.9166 11L15.5833 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> Quick Start</span>
                            </button></Link>
                        <Link to="/docs/vionbuild">
                            <button className="innerbtn btntextlast">
                                Next
                                <span className='greentext'>Quick Start <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M11.9166 7.33337L15.5833 11L11.9166 14.6667M6.41663 7.33337L10.0833 11L6.41663 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></span>
                            </button>
                        </Link>
                    </div>
                </div>


            </div>
            {/* <div className="right-sidebar">
                       <div className="main-heading">
                           <span>Table of Contents</span>
                       </div>
                       <div className="rightlinks">
                           <Link to="Getting started" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Getting started</Link>
                           <Link to="Start learning" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Start learning</Link>
                           <Link to="Vion Features" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Vion Features</Link>
                           <Link to="How to Use These Docs" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Why Vion</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Vion Features</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                           <Link to="terminology" activeClass="active-link"
                               spy={true} smooth={true} duration={300}>Need Help</Link>
                       </div>
                   </div> */}
        </div>
    )
}

export default Vioncreate