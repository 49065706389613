import React from 'react'
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
const Vioncore = () => {
    return (
        <div className="content-wrapper">
            <div className="inside-content">
                <h1>Core Components of Vion Blockchain Architecture</h1>
                <div>
                    <Element name="Getting started" className='Getting started'>
                        <div id="Getting started">
                            <ol className='alphabet'>
                                <li><span className='greentext'>Consensus Layer</span><br></br>
                                    <ul>
                                        <li><span className="greypara">The <span className='greentext'>Consensus Layer </span>is responsible for maintaining the integrity and security of the blockchain by ensuring agreement among nodes about the state of the network.</span></li>
                                        <li><span className="greypara">Vion uses an <span className='greentext'>energy-efficient consensus mechanism</span> (e.g., Proof-of-Stake), which reduces energy consumption while ensuring decentralization and high throughput.</span></li>
                                        <li><span className="greentext">Key Features:<br></br>
                                            <li>Security against double-spending and 51% attacks.</li>
                                            <li>Fast block finality for improved transaction speed.</li>
                                            <li>Low energy consumption compared to Proof-of-Work (PoW).</li>
                                        </span></li>

                                    </ul>
                                </li>
                                <li> <span className='greentext'>Networking Layer</span><br></br>
                                    <ul>
                                        <li><span className="greypara">The <span className='greentext'>Networking Layer </span> powers the peer-to-peer (P2P) communication between nodes, enabling decentralization.</span></li>
                                        <li><span className="greentext">Responsibilities:<br></br>
                                            <li>Propagation of transactions and blocks across the network.
                                            </li>
                                            <li>Efficient handling of node discovery, IP allocation, and message routing.
                                            </li>
                                            <li>Prevention of spam and network congestion through advanced filtering algorithms.</li>
                                        </span></li>

                                    </ul>
                                </li>
                                <li><span className='greentext'>Execution Layer</span><br></br>
                                    <ul>
                                        <li><span className="greypara">The<span className='greentext'> Execution Layer </span>  handles smart contract deployment and transaction processing.</span></li>
                                        <li><span className="greypara">Designed to support<span className='greentext'> EVM compatibility </span> allowing developers to port their Ethereum-based dApps seamlessly.</span></li>
                                        <li><span className="greypara">Optimized for high throughput and minimal latency. </span></li>
                                        <li><span className="greypara">Supports complex transactions, including multi-signature operations and advanced DeFi protocols.                                           </span></li>
                                    </ul>
                                </li>
                                <li><span className='greentext'>Data Layer</span><br></br>
                                    <ul>
                                        <li><span className="greypara">The <span className='greentext'>Data Layer </span> ensures the secure storage and retrieval of blockchain data, such as transaction records, block headers, and smart contract states.</span></li>
                                        <li><span className="greentext">Features:<br></br>
                                            <li>Uses advanced cryptographic hashing to secure the integrity of data.</li>
                                            <li>Implements pruning and compression techniques to maintain a lightweight blockchain</li>                                           </span></li>

                                    </ul>
                                </li>
                                <li><span className='greentext'>Governance Layer</span><br></br>
                                    <ul>
                                        <li><span className="greypara">The <span className='greentext'>Governance Layer </span>  enables community-driven decision-making for upgrades and network management</span></li>

                                        <li><span className="greypara">Allows Vion token holders to propose and vote on changes to the protocol, ensuring transparency and inclusivity.</span></li>

                                    </ul>
                                </li>


                            </ol>
                        </div>
                    </Element>
                    {/* <Element name="Start learning" className='Start learning'>
                           <div id="Start learning">
                               <h3>Start learning <span className="green">#</span></h3>
                               <p>
                                   Eros dictum ante class sagittis dolor suspendisse pretium. Rutrum amet penatibus velit amet arcu sociosqu vivamus id.
                               </p>
                               <ul>
                                   <li>Accounts  <span className="greypara">- Viverra habitant nisl aptent neque faucibus.</span></li>
                                   <li>Fee on Vion  <span className="greypara">- Viverra habitant nisl aptent neque faucibus.</span></li>
                                   <li>Transactions  <span className="greypara">- Viverra habitant nisl aptent neque faucibus.</span></li>
                                   <li>Programs Derived Address  <span className="greypara">- Viverra habitant nisl aptent neque faucibus.</span></li>
                                   <li>Cross Program Invocation  <span className="greypara">- Himenaeos vitae natoque class; elit malesuada maximus fringilla congue dictum scelerisque.</span></li>
                               </ul>
                           </div>
                       </Element>
                       <Element name="Vion Features" className='Vion Features'>
                           <div id="Vion Features">
                               <h3>Vion Features <span className="green">#</span></h3>
                               <p>
                                   Urna at dictum nunc eu donec; sociosqu lacinia. Pharetra suspendisse sagittis convallis nullam commodo interdum. Iaculis malesuada lobortis nisi curabitur massa nisl.
                               </p>
                               <div className="feraturesmain">
                                   <div className="featuresinnerhead">
                                       <h4 className="featurehead heading">Feature</h4>
                                       <h4 className="featurehead para">Description</h4>
                                   </div>
                                   <div className="featuresinner">
                                       <p className="featurespara heading">Onchain Program Development</p>
                                       <p className="featurespara para">Nec nec vehicula sodales posuere porttitor molestie. Massa volutpat congue ut fermentum commodo feugiat.</p>
                                   </div>
                                   <div className="featuresinner dark">
                                       <p className="featurespara heading">400ms Slot Times</p>
                                       <p className="featurespara para">Elit tellus felis malesuada mi tincidunt facilisis vulputate. Id diam sed integer urna platea ac. Vulputate fusce eros mi nulla ultricies volutpat, laoreet sit. </p>
                                   </div>
                                   <div className="featuresinner">
                                       <p className="featurespara heading">Onchain Program Development</p>
                                       <p className="featurespara para">Nec nec vehicula sodales posuere porttitor molestie. Massa volutpat congue ut fermentum commodo feugiat.</p>
                                   </div>
                                   <div className="featuresinner dark">
                                       <p className="featurespara heading">400ms Slot Times</p>
                                       <p className="featurespara para">Elit tellus felis malesuada mi tincidunt facilisis vulputate. Id diam sed integer urna platea ac. Vulputate fusce eros mi nulla ultricies volutpat, laoreet sit. </p>
                                   </div>
                               </div>
                           </div>
                       </Element>
                       <Element name="How to Use These Docs" className='How to Use These Docs'>
                           <div id="How to Use These Docs">
                               <h3>How to Use These Docs <span className="green">#</span></h3>
                               <p>
                                   At praesent consectetur elit etiam quam vitae. Morbi litora dictum iaculis enim lectus pretium. Dis hendrerit cras platea facilisis a rutrum. Tristique ligula vivamus habitasse imperdiet fringilla dapibus. Aliquet cursus convallis nisi rhoncus tortor neque. Placerat praesent maecenas; primis et eu tincidunt mattis. Aenean dis luctus magnis; potenti augue amet velit.
                               </p>
                               <p>
                                   usto molestie orci habitant vehicula sodales phasellus. <span className="green">Scelerisque ridiculus</span> fusce nisi in platea. Sem conubia arcu eleifend aptent adipiscing.
                               </p>
                           </div>
                       </Element> */}
                    <div className="bottombtns">
                        <Link to="/docs/vionarchi">
                            <button className="innerbtn">
                                Previous
                                <span className='greentext'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M10.0833 7.33337L6.41659 11L10.0833 14.6667M15.5833 7.33337L11.9166 11L15.5833 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg> Quick Start</span>
                            </button>
                        </Link>
                        <Link to="/docs/viondeveloper">
                        <button className="innerbtn btntextlast">
                            Next
                            <span className='greentext'>Quick Start <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M11.9166 7.33337L15.5833 11L11.9166 14.6667M6.41663 7.33337L10.0833 11L6.41663 14.6667" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></span>
                        </button>
                        </Link>
                    </div>
                </div>


            </div>
            {/* <div className="right-sidebar">
                   <div className="main-heading">
                       <span>Table of Contents</span>
                   </div>
                   <div className="rightlinks">
                       <Link to="Getting started" activeClass="active-link"
                           spy={true} smooth={true} duration={300}>Getting started</Link>
                       <Link to="Start learning" activeClass="active-link"
                           spy={true} smooth={true} duration={300}>Start learning</Link>
                       <Link to="Vion Features" activeClass="active-link"
                           spy={true} smooth={true} duration={300}>Vion Features</Link>
                       <Link to="How to Use These Docs" activeClass="active-link"
                           spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                       <Link to="terminology" activeClass="active-link"
                           spy={true} smooth={true} duration={300}>Why Vion</Link>
                       <Link to="terminology" activeClass="active-link"
                           spy={true} smooth={true} duration={300}>Vion Features</Link>
                       <Link to="terminology" activeClass="active-link"
                           spy={true} smooth={true} duration={300}>How to Use These Docs</Link>
                       <Link to="terminology" activeClass="active-link"
                           spy={true} smooth={true} duration={300}>Need Help</Link>
                   </div>
               </div> */}
        </div>
    )
}

export default Vioncore