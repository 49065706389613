import './app.scss';
import Blog from './components/Blog/Blog.js';
import BlogDetail from './components/Blog/BlogDetail.js';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Technicalpapers from './components/Technicalpapers/Technicalpapers.js';
import Learn from './components/Learn/Learn.js';
import Learnone from './components/Learn/Learnone.js';
import Events from './components/Events/Events.js';
import Learn2 from './components/Learn/Learn2.js';
import Docs from './components/Docs/Docs.js';
import Communityevents from './components/Communityevents/Communityevents.js';
import Resourcehub from './components/Resourcehub/Resourcehub.js';
import Validators from './components/Validators/Validators.js';
import Providers from './components/Providers/Providers.js';
import Health from './components/Health/Health.js';
import Generator from './components/Generator/Generator.js';
import Viondex from './components/Viondex/Viondex.js';
import Vionwallet from './components/Vionwallet/Vionwallet.js';
import Tempor from './components/Tempor/Tempor.js';
import Marketplace from './components/Marketplace/Marketplace.js';
import Bridge from './components/Bridge/Bridge.js';
import Careers from './components/Careers/Careers.js';
import Terms from './components/Terms/Terms.js';
import Privacy from './components/Privacy/Privacy.js';
import Grant from './components/Grant/Grant.js';
import ApplyForVion from './components/Applyforviongrantprogram/ApplyForVion.js';
import Applyforjob from './components/Applyforjob/Applyforjob.js';
import Contactus from './components/Contactus/Contactus.js';



function App() {
  return (
    <>
          <Router>
            <Routes>
              <Route exact path='/' element={<Landing />} />
              <Route exact path='/blog' element={<Blog />} />
              <Route exact path='/blogdetail/:id' element={<BlogDetail />} />
              <Route exact path='/technical' element={<Technicalpapers />} />
              <Route exact path='/learn' element={<Learn />} />
              <Route exact path='/learnone' element={<Learnone />} />
              <Route exact path='/learntwo' element={<Learn2 />} />
              <Route exact path='/events' element={<Events />} />
              <Route path="/docs/:section" element={<Docs />} />
              <Route exact path='/communityevents' element={<Communityevents />} />
              <Route exact path='/resource' element={<Resourcehub />} />
              <Route exact path='/validators' element={<Validators/>} />
              <Route exact path='/providers' element={<Providers/>} />
              <Route exact path='/health' element={<Health/>} />
              <Route exact path='/generator' element={<Generator/>} />
              <Route exact path='/viondex' element={<Viondex/>} />
              <Route exact path='/vionwallet' element={<Vionwallet/>} />
              <Route exact path='/tempor' element={<Tempor/>} />
              <Route exact path='/marketplace' element={<Marketplace/>} />
              <Route exact path='/bridge' element={<Bridge/>} />
              <Route exact path='/careers' element={<Careers/>} />
              <Route exact path='/terms' element={<Terms/>} />
              <Route exact path='/privacy' element={<Privacy/>} />
              <Route exact path='/grant' element={<Grant/>} />
              <Route exact path='/applyforvion' element={<ApplyForVion/>} />
              <Route exact path='/apply' element={<Applyforjob/>} />
              <Route exact path='/contactus' element={<Contactus/>} />

              
            </Routes>
          </Router>
    </>
  );
}

export default App;
